<template>
  <!--  这个是群组信息-->
  <div>
    <div v-for="item in group" :key="item.userId" class="text">
      <el-row class="row">
        <el-col :span="4" class="col">
          <el-avatar shape="square" :size="60" :src="item.imgUrl"></el-avatar>
        </el-col>
        <el-col :span="20" class="col">
          <div class="con">
            <div class="name">{{ item.name }}</div>
            <div class="content">
              <el-row>
                <el-col :span="16" class="information">
                  {{ item.name }}
                </el-col>
                <el-col :span="8">
                  <el-badge class="mark" :value="12"/>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "group",
  data() {
    return {
      group: [
        {
          userId: "21",
          imgUrl: "https://jokerak.oss-cn-beijing.aliyuncs.com/avatar",
          number: 1,
          name: "kk"
        },
        {
          userId: "2121",
          imgUrl: "https://jokerak.oss-cn-beijing.aliyuncs.com/avatar",
          number: 1,
          name: "kk",

        },
        {
          userId: "221",
          imgUrl: "https://jokerak.oss-cn-beijing.aliyuncs.com/avatar",
          number: 1,
          name: "kk"
        }],
    }
  }
}
</script>

<style scoped >

.text {
  height: 6.25rem;
  width: 100%;
}
.row {
  height: 100%;
  width: 100%;

  .col {
    height: 100%;

    .img {
      height: 3.75rem;
      width: 3.75rem;

      margin: 10px auto;
    }
  }
}

.con {
  height: 100%;
  width: 100%;

  .name {
    height: 30%;
    width: 100%;
    text-align: left;
    font-size: 1.875rem;
  }

  .content {
    height: 70%;
    width: 100%;

    .el-row {
      height: 100%;
      width: 100%;

      .el-col {
        height: 100%;
      }
    }

  }
}

.information {
  text-align: left;
  font-size: 1.875rem;
  line-height: 3.75rem;
  height: 3.75rem;

}

.mark {
  font-size: 1.875rem;
}

</style>
