<template>
  <!--    1 对 1 对话。聊天框-->
  <div class="friend">
    <div v-for="(item,index) in friend" :key="index" class="text" @click="change(item)">
      <el-row class="row">
        <el-col :span="4" class="col">
          <el-avatar shape="square" :size="60" :src="item.avatar"></el-avatar>
        </el-col>
        <el-col :span="20" class="col">
          <div class="con">
            <div class="name">{{ item.friendName }}</div>
            <div class="content">
              <el-row>
                <el-col :span="16" class="information">
                  {{ item.text }}
                </el-col>
                <el-col :span="8">
                  <el-badge class="mark" :value="item.number"/>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getFriend, addFriend} from "@/api/user";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {MessageBox} from "element-ui"
import Global from "@/config/global";

export default {

  name: "friend",
  props: {},
  methods: {
    // 切换用户信息
    change(friend) {
      this.$store.dispatch("chat/changeFriend", friend)
    },
    stompAll() {

      if (this.stompClient !== undefined) {
        // 订阅 由全局的消息
        this.stompClient.subscribe('/exchange/sendToAll/all', function (response) {
          let message = JSON.parse(response.body);
          //展示广播的接收的内容接收
          this.$message({
            showClose: true,
            message: "全局消息: " + message,
            type: 'success'
          });
        });
      } else {
        this.$message("websocket 为空值")
      }
    },
    /**
     * 监听自己队列 看是否有信息发送过来
     */
    stompQueue() {
      const that = this;
      if (this.stompClient !== undefined) {
        // 订阅自己的消息
        let username = this.$store.state.user.name
        console.log("正在监听: " + username)
        //通过stompClient.subscribe订阅 /topic/getResponse 目标(destination)发送的消息（队列接收信息）
        this.stompClient.subscribe("/queue/" + username, function (response) {
          let message = JSON.parse(response.body);
          console.log("单独接受消息" + message);
          // 添加消息
          if (message.type === 1) {
            that.one(message)
          } else if (message.type === 2) {
            console.log("群聊")
            // 添加好友
          } else if (message.type === 3) {
            that.add(message)
          }
        })

      } else {
        this.$message({
          message: "监听不了自己" + this.stompClient === undefined,
          type: "warning"
        })
      }
    },
    // 添加好友聊天的功能
    one(message) {
      this.friend.forEach(item => {
        if (item.friendId === message.fromId) {
          item.number++;
          let data = {
            id: item.friendId,
            content: {
              flag: true,
              name: item.friendName,
              avatar: item.avatar,
              text: message.context,
            }
          }
          // 设置 聊天的全部信息
          this.$store.dispatch("chat/changeData", data)
        }
      })

    },
    add(message) {
      MessageBox.alert(message.fromName + "请求添加好友", '添加好友', {
            confirmButtonText: '确定',
            callback: action => {
              console.log(action)
              addFriend(this.$store.state.user.id, message.fromId)
                  .then(response => {
                    if (response.data.code !== "20001") {
                      //   this.$message(response.data.data.friend);
                      // 重新加载用户信息
                      this.loadFriend();
                    }
                  }).catch(error => {
                console.log(error)
              })
            }
          }
      )
    },
    /**
     * 加载好友列表
     */
    loadFriend() {
      const that = this;
      getFriend(this.$store.state.user.id).then(result => {
        if (result.data.code === "20001") {
          this.$message("对不起，获取好友列表失败")
        } else {
          this.friend = result.data.friend;
          let allData = new Map();
          this.friend.forEach(item => {
            // 加载朋友的信息
            allData.set(item.friendId, [])
          })
          // 重新加载用户信息，
          that.$store.dispatch("chat/setAllData", allData)
          // 设置当前浏览信息
          that.$store.dispatch("chat/changeFriend", that.friend[0])
        }
      }).catch(result => {
        console.log(result)
      })
    }
  },

  data() {
    return {
      friend: [],
      stompClient: Object
    }
  },

  mounted() {
    // 加载好友列表
    this.loadFriend();
    let socket = new SockJS(Global.BASE_URL + ":8006/myChat"); //连接SockJS的endpoint名称为""
    this.stompClient = Stomp.over(socket); //使用STMOP子协议的WebSocket客户端
    const that = this;

    this.stompClient.connect({}, function (frame) { //连接WebSocket服务端
      // 订阅 由全局的消息
      that.$message({
        message: "连接成功" + frame,
        type: "success",
        duration:2*1000
      })
      that.stompAll();
      that.stompQueue();
    });
  }
}
</script>

<style scoped >
.friend {
  height: 100%;
  width: 100%;
}

.text {

  height: 6.25rem;
  width: 100%;
}

.row {
  height: 100%;
  width: 100%;

  .col {
    height: 100%;

    .img {
      height: 3.75rem;
      width: 3.75rem;

      margin: 10px auto;
    }
  }
}

.con {
  height: 100%;
  width: 100%;

  .name {
    height: 30%;
    width: 100%;
    text-align: left;
    font-size: 1.875rem;
  }

  .content {
    height: 70%;
    width: 100%;

    .el-row {
      height: 100%;
      width: 100%;

      .el-col {
        height: 100%;
      }
    }

  }
}

.information {
  text-align: left;
  font-size: 1.875rem;
  line-height: 3.75rem;
  height: 3.75rem;

}

.mark {
  font-size: 1.875rem;
}
</style>
