<template >
  <div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="头像"
          width="180">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.userImg"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="username"
          label="姓名"
          width="180">
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row.username,scope.row.userId)" type="danger" size="small" >添加好友</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getAllFriend} from "@/api/user";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import Global from "@/config/global";
export default {
  name: "AddFriend",

  mounted() {
    let socket = new SockJS(Global.BASE_URL+":8006/myChat"); //连接SockJS的endpoint名称为""
    this.stompClient = Stomp.over(socket); //使用STMOP子协议的WebSocket客户端
    this.stompClient.connect({}, function (frame) { //连接WebSocket服务端
      console.log('Connected:' + frame);
    });

    getAllFriend(this.$store.state.user.id).then(result=>{
      const  data =result.data;
      if(data.code==="20001"){
        this.$message({
          message:"对不起，寻找好友失败",
          type:"warning"
        })
      }else {
        this.tableData=data.friend
      }
    })
  },

  methods: {

    handleClick(name,id) {
      let message={
        type:3,
        fromId:this.$store.state.user.id,
        fromName:this.$store.state.user.name,
        toName:name,
        toId:id,
        context:"请求添加好友"
      }
      this.stompClient.send("/app/queueUser",{},JSON.stringify(message))
    },
  },
  data() {
    return {

      tableData: [],
      stompClient:Object,
    }
  },
}
</script>

<style scoped >

</style>
