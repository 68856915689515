<template>
  <!-- 这个是即时通信  -->
  <div class="chat">
    <el-row :gutter="20">
      <el-col :span="8"  class="chat_left">
        <!--          用来切换用户还是聊天对象-->
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true">

          <el-tab-pane label="好友列表" name="first">
            <FriendList></FriendList>
          </el-tab-pane>
          <el-tab-pane label="群聊列表" name="second">
            <Group></Group>
          </el-tab-pane>

          <el-tab-pane label="添加好友" name="third">
            <AddFriend></AddFriend>
          </el-tab-pane>
        </el-tabs>
      </el-col>

      <el-col :span="15" class="chat_right" :offset="1">
        <!--  发送消息的对话框-->
        <el-container>
          <el-header>
            {{ friend.friendName}}
          </el-header>
          <el-main>
            <div v-for="(item,index) in data" :key="index">
              <el-row v-if="item.flag">
                <el-col :span="4">
                  <el-avatar size="small" :src="item.avatar"></el-avatar>
                </el-col>
                <el-col :span="12" class="context-left">
                  <div>
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.text }}
                  </div>
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :span="12" :offset="8" class="context-right">
                  <div>
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.text }}
                  </div>
                </el-col>
                <el-col :span="4">
                  <el-avatar size="small" src="sd">
                  </el-avatar>
                </el-col>
              </el-row>
            </div>
          </el-main>
          <!--          发送信息-->
          <el-footer class="chat_bottom">

            <el-row>
              <el-col :span="16">
                <el-input v-model="context"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="primary" round @click="send">发送</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<script>

/**
 * 使用sockjs-client+stompJs 进行 websocket连接的方法
 */
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import FriendList from "@/views/chat/FriendList";
import Group from "@/views/chat/Group";
import AddFriend from "@/views/chat/AddFriend";
import Global from "@/config/global";


export default {
  name: "Chat",
  components: {AddFriend, Group, FriendList},
  data() {
    return {
      context: "",
      stompClient: Object,
      flag: false,
      activeName: 'first',
      data:"",
      friend:this.$store.state.chat.friend
    };
  },
  // 需要加载
  mounted() {
    let socket = new SockJS("https://aliyun.jokerak.com:8082/myChat"); //连接SockJS的endpoint名称为""
    this.stompClient = Stomp.over(socket); //使用STMOP子协议的WebSocket客户端
    this.stompClient.connect({}, function (frame) { //连接WebSocket服务端
      console.log('Connected:' + frame);
    });
  },
  methods: {

    handleClick(tab, event) {
      console.log(tab, event);
    },

    send() {
      if(this.context!==undefined){
        let message = {
          type: 1,
          fromId: this.$store.state.user.id,
          fromName: this.$store.state.user.name,
          toName: this.friend.friendName,
          toId: this.friend.friendId,
          context: this.context
        }
        this.stompClient.send("/app/queueUser", {}, JSON.stringify(message))

        let data={
          id:this.friend.friendId,
          content:{
            flag:false,
            name:this.$store.state.user.name,
            avatar:this.$store.state.user.avatar,
            text:this.context
          }
        }

        // 添加到所有的信息
        this.$store.dispatch("chat/changeData",data)
        this.context="";

      }else {
        this.$message("不能为空")
      }
    }
  },
  computed: {
    computed_friend: {
     get(){
       return this.$store.state.chat.friend
     },
      set(v){
       this.friend=v;
      }
    },
    computed_data:{
      get(){
        return this.$store.state.chat.data.get(this.$store.state.chat.friend.friendId)
      },
      set(v){
        this.data=v;
      }
    }
  },
  // 监听vuex 中值的变化
  watch: {
    '$store.state.chat.friend':function (val) {
      console.log("好友信息修改")
      this.computed_friend=val;
      this.computed_data=this.$store.state.chat.data.get(val.friendId);
    },
    '$store.state.chat.data':function (val) {
      console.log("聊天数据修改了")
      this.computed_data=val.get(this.$store.state.chat.friend.friendId);
    }
  }
}
</script>

<style scoped >
.chat_bottom{
  border-top: 1px solid  #333;
  padding-top: 10px;
}
.chat_right{
  border: 1px solid red;
}
.chat_left{
  border: 1px solid red;
}
.chat {
  height: 100%;
  width: 100%;


}
.aside {
  width: 40% !important;
  overflow: hidden;
}
.context-left {
  text-align: left;
}

.context-right {
  text-align: right;
}

.left {
  border: 1px solid black;
  height: 100%;
  width: 100%;
}

.right {
  border: 1px solid #0078ff;
}
</style>
